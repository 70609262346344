import styled from 'styled-components';

import { COLORS } from 'theme';

export const MenuWrapperStyle = {
  Root: styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    background: ${COLORS.violet};

    @media print {
      background: none;
    }
  `,

  ContentWrapper: styled.div`
    width: 100%;
    background-color: ${COLORS.white};
    border-radius: 25px 0px 0px 25px;
    overflow: hidden;
    display: grid;
    grid-template-rows: auto 1fr;
    row-gap: 10px;
  `,

  NavigationWrapper: styled.div`
    padding-top: 20px;
    padding-left: 40px;
  `,
};
