import { RefObject } from 'react';
import { TGameVersion } from '@avid/common';

import { firebaseStorage } from 'services/firebase';

export const fillLinkAndDownloadConstantsFile = async (
  path: string,
  ref: RefObject<HTMLAnchorElement>
) => {
  if (!ref.current) {
    return false;
  }

  try {
    const downloadUrl = await firebaseStorage.getConstantsFileUrl(path);

    ref.current.href = downloadUrl;
    ref.current.click();

    return true;
  } catch {
    return false;
  }
};

export const addPathVersion = (
  version: TGameVersion | undefined,
  path: string
) => {
  const prefix = version ? `versions/${version}/` : '';
  return prefix + path;
};
