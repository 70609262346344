import { useState } from 'react';
import { useSelector } from 'react-redux';

import { signOut } from 'services/firebase.api';
import { history } from 'services/history';
import { IReduxState } from 'typings/redux';
import { STRINGS } from 'constants/string';
import { ROUTES } from 'constants/routes';

import { HeaderStyle as Styles } from './header.style';
import { IDS } from './header.constants';

const { USER_EMAIL, USER_PROFILE_ITEM, USER_LOGOUT_ITEM } = IDS;

export const Header = () => {
  const [userMenu, showHideMenu] = useState(false);

  const currentUser = useSelector(
    (state: IReduxState) => state.main.currentUser
  );

  const setMenu = () => {
    showHideMenu(!userMenu);
  };

  const onSignOut = () => {
    signOut();
    history.push(ROUTES.START);
  };

  const { menuDrop } = STRINGS.HEADER;

  if (!currentUser) {
    return null;
  }

  return (
    <Styles.Root onClick={setMenu}>
      <Styles.UserIcon type="user" />

      <Styles.UserName id={USER_EMAIL}>
        {currentUser?.displayName || currentUser?.email}
      </Styles.UserName>

      <Styles.Triangle type="triangle" />

      {userMenu && (
        <Styles.Menu>
          <Styles.MenuItem id={USER_PROFILE_ITEM}>
            <Styles.MenuIcon type="userBlack" />

            {menuDrop.profile}
          </Styles.MenuItem>

          <Styles.MenuItem id={USER_LOGOUT_ITEM} onClick={onSignOut}>
            <Styles.MenuIcon type="logOut" />

            {menuDrop.logout}
          </Styles.MenuItem>
        </Styles.Menu>
      )}
    </Styles.Root>
  );
};
