import styled from 'styled-components';
import { Theme } from 'theme';

import { Section } from 'components/section';

const { Button } = Theme;

export const ExportDataStyles = {
  Container: styled(Section)`
    display: flex;
    flex-direction: column;
    height: 100%;
  `,
  ExportButtonWrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: fit-content;
  `,
  ExportButton: styled(Button)`
    margin: 0;
  `,
};
