import { ICsvUploader } from 'typings/constants-uploading.typing';

import { CSVReaderService } from '../csv-reader.service';

export class CsvUploading {
  constructor(
    private constantsUploader: ICsvUploader
    // eslint-disable-next-line no-empty-function
  ) {}

  async fromFile<T extends []>(file: File) {
    const csvArray = await CSVReaderService.readAsArray(file);

    const beforeData = this.constantsUploader.beforeParseRows
      ? await this.constantsUploader.beforeParseRows?.()
      : undefined;

    const parsed = this.constantsUploader.parseRows(csvArray as T, beforeData);

    return this.constantsUploader.upload(parsed);
  }
}
