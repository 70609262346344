
import { IRound } from '@avid/common';

import { Paragraph } from 'components/typography';
import { GameProgressBar } from 'components/progress-bar';

import { Energy, Players, RoundTimer, Summary } from './components';

import { ActiveGamePanelStyles as Styles } from './active-game-panel.styles';

interface IProps {
  gameTitle: string;
  gameCode: string;
  roundsNumber: number;
  timeElapsed: number;
  roundDuration: number;
  addTimeElapsed: (interval: number) => void;
  rounds?: IRound[];
  endRound?: () => void;
}

export const ActiveGamePanel = (props: IProps) => {
  const {
    gameCode,
    gameTitle,
    roundDuration,
    roundsNumber,
    timeElapsed,
    rounds = [],
    endRound,
    addTimeElapsed,
  } = props;

  const activeRound = rounds?.length ? rounds.length : 1;

  const activeRoundStart = rounds?.[rounds?.length - 1]?.endTime
    ? null
    : rounds?.[rounds?.length - 1]?.startTime;

  return (
    <Styles.Root>
      <Styles.Header>
        <Paragraph typography="QuicksandBold18">
          Game Name “{gameTitle}”
        </Paragraph>
        <Paragraph typography="QuicksandBold18">Game Code {gameCode}</Paragraph>
      </Styles.Header>

      <Styles.MiddleWrapper>
        <Styles.RectanglesWrapper>
          <Players gameCode={gameCode} />
          <Energy gameCode={gameCode} />
        </Styles.RectanglesWrapper>

        <RoundTimer
          round={activeRound}
          roundDuration={roundDuration}
          roundStart={activeRoundStart}
          addTimeElapsed={addTimeElapsed}
          endRound={endRound}
        />
      </Styles.MiddleWrapper>

      <Styles.SummaryWrapper>
        <Summary
          roundsNumber={roundsNumber}
          roundDuration={roundDuration}
          timeElapsed={timeElapsed}
        />
      </Styles.SummaryWrapper>

      <Styles.GameProgressWrapper>
        <GameProgressBar activeRounds={rounds} roundsNumber={+roundsNumber} />
      </Styles.GameProgressWrapper>
    </Styles.Root>
  );
};
