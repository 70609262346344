import styled from 'styled-components';

import { Grid } from 'components/UI';
import { COLORS } from 'theme';
import { sortGamesByDateAndStatus } from 'services/utils';

import { HomeStyle } from '../../home.style';
import { CreateBlock } from '../create-block';
import { useReduxSelector } from 'services/hooks';
import { AdminGame } from '../admin-game';

const Styles = {
  ContentWrapper: styled.div`
    margin: 0 40px 20px 40px;
  `,

  GamesWrapper: styled(Grid)`
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    column-gap: 32px;
    row-gap: 24px;
  `,
};

export const AdminGames = () => {
  const games = useReduxSelector((redux) => redux.main.games);

  const sorted = sortGamesByDateAndStatus(games);

  const RenderGames = sorted.map((game) => (
    <AdminGame
      key={game.config.gameCode}
      gameCode={game.config.gameCode}
      status={game.status}
      currentRound={game.rounds?.length}
      title={game.config.gameTitle}
      description={game.config.description}
    />
  ));

  return (
    <HomeStyle.Wrapper backgroundColor={COLORS.white}>
      <Styles.ContentWrapper>
        <CreateBlock />

        <HomeStyle.Title>Your games</HomeStyle.Title>

        <Styles.GamesWrapper>{RenderGames}</Styles.GamesWrapper>
      </Styles.ContentWrapper>
    </HomeStyle.Wrapper>
  );
};
