import { RouteComponentProps, StaticContext } from 'react-router';

import { StatisticMenu } from 'components/statistic-menu';
import { COLORS } from 'theme';

import { useStatistics } from './statistics.state';
import { StatisticStyle as Styles } from './statistics.style';
import { StatisticsContent } from 'components/statistics-content';
import { GetReviews } from './components';

interface IMatchParams {
  gameCode?: string;
}

export const Statistics = (
  props: RouteComponentProps<IMatchParams, StaticContext, string>
) => {
  const { match, location } = props;

  const routeGameCode = match.params.gameCode || location.state;

  const {
    isLoading,
    round,
    statistics,
    isSelectedGameFinished,
    gameCode,
    isGamesExist,
    onSelectGame,
    onChangeRound,
  } = useStatistics(routeGameCode);

  const RenderReviewsButton = gameCode &&
    isSelectedGameFinished &&
    statistics?.gameVersion === 'ntu' && <GetReviews gameCode={gameCode} />;

  return (
    <Styles.Wrapper backgroundColor={COLORS.white}>
      <StatisticMenu
        currentGameCode={gameCode}
        currentRound={round}
        selectGame={onSelectGame}
        onSelectSnapshot={onChangeRound}
      />

      <StatisticsContent
        isGameExists={isGamesExist}
        isLoading={isLoading}
        gameCode={gameCode}
        statistics={statistics}
        isAllowPrint
      />

      {RenderReviewsButton}
    </Styles.Wrapper>
  );
};
