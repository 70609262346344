import { ChangeEventHandler, useRef } from 'react';

import { SUPER_CALLABLE_ACTION_TYPES, callSuperAdmin } from 'services/api';

import { gamesUploadUtils } from './games-upload.utils';
import { useUpdateState } from 'services/hooks';

interface IState {
  isLoading: boolean;
  isError: boolean;
  file?: File;
}

const INIT_STATE: IState = {
  isLoading: false,
  isError: false,
};

export const useGameUpload = () => {
  const { state, updateState } = useUpdateState(INIT_STATE);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const onClickLink = () => inputRef.current?.click();

  const onError = (row: number) => {
    console.error(row);
  };

  const onChangeFile: ChangeEventHandler<HTMLInputElement> = (ev) => {
    const file = ev.target.files?.[0];

    if (!file) {
      return;
    }

    updateState({ file });
  };

  const onUpload = async () => {
    if (!state.file || state.isLoading) {
      return;
    }

    updateState({ isLoading: true, isError: false });

    const fileText = (await gamesUploadUtils.getFileText(state.file)) as string;

    const uploadGames = gamesUploadUtils.getConfigArrayFromText(
      fileText,
      onError
    );

    if (!uploadGames) {
      return;
    }

    try {
      await callSuperAdmin(SUPER_CALLABLE_ACTION_TYPES.UPLOAD_GAMES, {
        uploadGames,
      });

      updateState({ isLoading: true });
    } catch (error) {
      updateState({ isLoading: false, isError: true });
      console.error(error);
    }
  };

  const isUploadDisabled = !state.file || state.isLoading;

  return {
    ...state,
    isUploadDisabled,
    inputRef,
    onClickLink,
    onChangeFile,
    onUpload,
  };
};
