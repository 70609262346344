import styled from 'styled-components';

import { Section } from 'components/section';
import { Icon } from 'components/icons';

import { Theme } from 'theme';

const { Button, ButtonMedium, Title } = Theme;

interface IContentContainer {
  marginTop?: string;
}

export const ActiveGameStyle = {
  Wrapper: styled(Section)`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 40px 20px 40px;
    align-self: center;
  `,
  ContentContainer: styled.div<IContentContainer>`
    margin-top: ${(props) => props.marginTop};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  `,
  ActiveGamePanelWrapper: styled.div`
    margin-top: 16px;
  `,
  GameCreateBlock: styled.div`
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    margin: 0 40px 20px 40px;
    align-self: center;
  `,
  Title: styled(Title)`
    margin-top: 32px;
    margin-bottom: 32px;
    flex: 1;
  `,
  Icon: styled(Icon)`
    width: 476px;
  `,
  CountBoxes: styled.div`
    display: flex;
    flex-direction: column;
    width: 235.77px;
    height: 129px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    text-align: center;
  `,

  Button: styled(Button)`
    margin-top: 0;
    &:disabled {
      opacity: 0.7;
      cursor: auto;
    }
  `,
  ButtonMedium: styled(ButtonMedium)`
    margin-right: 24px;
    &:last-of-type {
      margin-right: 0;
    }
  `,
  BottomButtonsContainer: styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  `,
  TabWrapper: styled.div`
    padding-top: 64px;
  `,
};
