

import { AdminGames, HomeEmpty } from './components';
import { useHomeState } from './home.state';

export const Home = () => {
  const { games, gameCreate } = useHomeState();

  if (!games.length) {
    return <HomeEmpty gameCreate={gameCreate} isLoading={false} />;
  }

  return <AdminGames />;
};
