"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RawFirestore = void 0;
var RawFirestore = /** @class */ (function () {
    function RawFirestore(db) {
        this.db = db;
        // construct
    }
    RawFirestore.prototype.appFirestore = function () {
        return this.db;
    };
    RawFirestore.prototype.rootCollection = function (name) {
        return this.appFirestore().collection(name);
    };
    RawFirestore.prototype.cheatCodesCollection = function () {
        return this.rootCollection('cheat-codes');
    };
    RawFirestore.prototype.cheatCodeDoc = function (code) {
        return this.cheatCodesCollection().doc(code);
    };
    RawFirestore.prototype.getCheatCodesSnapshot = function () {
        return this.cheatCodesCollection().get();
    };
    RawFirestore.prototype.setCheatCode = function (code, data) {
        return this.cheatCodeDoc(code).set(data);
    };
    RawFirestore.prototype.clearCheatCodes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var collection;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCheatCodesSnapshot()];
                    case 1:
                        collection = _a.sent();
                        return [2 /*return*/, Promise.all(collection.docs.map(function (doc) { return doc.ref.delete(); }))];
                }
            });
        });
    };
    // Refs: games
    RawFirestore.prototype.gamesCollection = function () {
        return this.rootCollection('games');
    };
    RawFirestore.prototype.gameDoc = function (gameCode) {
        return this.gamesCollection().doc(gameCode);
    };
    RawFirestore.prototype.playersCollection = function (gameCode) {
        return this.gameDoc(gameCode).collection('users');
    };
    RawFirestore.prototype.playerDoc = function (gameCode, playerEmail) {
        return this.playersCollection(gameCode).doc(playerEmail);
    };
    RawFirestore.prototype.gameModsCollection = function (gameCode) {
        return this.gameDoc(gameCode).collection('mods');
    };
    RawFirestore.prototype.limitedEducationGameModDoc = function (gameCode) {
        return this.gameModsCollection(gameCode).doc('limitedEducation');
    };
    RawFirestore.prototype.limitedEducationGameModRoundsCollection = function (gameCode) {
        return this.limitedEducationGameModDoc(gameCode).collection('rounds');
    };
    RawFirestore.prototype.limitedEducationGameModRoundDoc = function (gameCode, round) {
        return this.limitedEducationGameModRoundsCollection(gameCode).doc(round.toString());
    };
    // Refs: constants
    RawFirestore.prototype.constantsCollection = function () {
        return this.rootCollection('constants');
    };
    RawFirestore.prototype.createCharacterConstantsDoc = function () {
        return this.constantsCollection().doc('create_character');
    };
    RawFirestore.prototype.educationConstantsDoc = function () {
        return this.constantsCollection().doc('education');
    };
    RawFirestore.prototype.gameConstantsDoc = function () {
        return this.constantsCollection().doc('game');
    };
    RawFirestore.prototype.workConstantsDoc = function () {
        return this.constantsCollection().doc('work-constants');
    };
    RawFirestore.prototype.shopConstantsDoc = function () {
        return this.constantsCollection().doc('shop');
    };
    // Refs: versions
    RawFirestore.prototype.versionsCollection = function () {
        return this.rootCollection('versions');
    };
    RawFirestore.prototype.versionDoc = function (version) {
        return this.versionsCollection().doc(version);
    };
    RawFirestore.prototype.lifeCardsCollection = function (version) {
        return this.versionDoc(version).collection('life-cards');
    };
    RawFirestore.prototype.lifeCardsDoc = function (version) {
        return this.lifeCardsCollection(version).doc('life-cards');
    };
    RawFirestore.prototype.allSkillsCollection = function (version) {
        return this.versionDoc(version).collection('all-skills');
    };
    RawFirestore.prototype.allSkillsDoc = function (version) {
        return this.allSkillsCollection(version).doc('all-skills');
    };
    RawFirestore.prototype.versionConstantsCollection = function (version) {
        return this.versionDoc(version).collection('constants');
    };
    RawFirestore.prototype.valuesDoc = function (version) {
        return this.versionConstantsCollection(version).doc('values');
    };
    RawFirestore.prototype.companyValuesDoc = function (version) {
        return this.versionConstantsCollection(version).doc('company-values');
    };
    RawFirestore.prototype.educationCollection = function (version) {
        return this.versionDoc(version).collection('education');
    };
    RawFirestore.prototype.educationLevelsDoc = function (version) {
        return this.educationCollection(version).doc('levels');
    };
    RawFirestore.prototype.educationQuestionsCollection = function (version) {
        return this.educationLevelsDoc(version).collection('questions');
    };
    RawFirestore.prototype.educationQuestionsDoc = function (version, part) {
        return this.educationQuestionsCollection(version).doc(part);
    };
    RawFirestore.prototype.questionsAmountCollection = function (version) {
        return this.educationLevelsDoc(version).collection('questionsAmount');
    };
    RawFirestore.prototype.versionSectorsCollection = function (version) {
        return this.versionDoc(version).collection('sectors');
    };
    RawFirestore.prototype.sectorDoc = function (version, sector) {
        return this.versionSectorsCollection(version).doc(sector);
    };
    RawFirestore.prototype.sectorEducationCollection = function (version, sector) {
        return this.sectorDoc(version, sector).collection('education');
    };
    RawFirestore.prototype.sectorSkillsDoc = function (version, sector) {
        return this.sectorEducationCollection(version, sector).doc('skills');
    };
    RawFirestore.prototype.sectorWorkCollection = function (version, sector) {
        return this.sectorDoc(version, sector).collection('work');
    };
    RawFirestore.prototype.careerTreeDoc = function (version, sector) {
        return this.sectorWorkCollection(version, sector).doc('careerTree');
    };
    RawFirestore.prototype.companiesDoc = function (version, sector) {
        return this.sectorWorkCollection(version, sector).doc('companies');
    };
    RawFirestore.prototype.highestPositionsDoc = function (version, sector) {
        return this.sectorWorkCollection(version, sector).doc('highestPositions');
    };
    RawFirestore.prototype.jobsDoc = function (version, sector) {
        return this.sectorWorkCollection(version, sector).doc('jobs');
    };
    RawFirestore.prototype.jobCollection = function (version, sector, job) {
        return this.jobsDoc(version, sector).collection(job);
    };
    RawFirestore.prototype.jobDescriptionDoc = function (params) {
        var version = params.version, sector = params.sector, job = params.job;
        return this.jobCollection(version, sector, job).doc('description');
    };
    RawFirestore.prototype.jobQuestionsDoc = function (params) {
        var version = params.version, sector = params.sector, job = params.job;
        return this.jobCollection(version, sector, job).doc('questions');
    };
    RawFirestore.prototype.jobSalaryDoc = function (params) {
        var version = params.version, sector = params.sector, job = params.job;
        return this.jobCollection(version, sector, job).doc('salary');
    };
    RawFirestore.prototype.jobSkillsDoc = function (params) {
        var version = params.version, sector = params.sector, job = params.job;
        return this.jobCollection(version, sector, job).doc('skills');
    };
    RawFirestore.prototype.questionsAmountStudyDoc = function (version, study) {
        return this.questionsAmountCollection(version).doc(study);
    };
    RawFirestore.prototype.setCheatCodes = function (cheatCodes) {
        var _this = this;
        return Promise.all(cheatCodes.map(function (_a) {
            var code = _a[0], codeData = _a[1];
            return _this.setCheatCode(code, codeData);
        }));
    };
    RawFirestore.prototype.updateWorkConstants = function (data) {
        return this.workConstantsDoc().update(data);
    };
    RawFirestore.prototype.updateEducationConstants = function (data) {
        return this.educationConstantsDoc().update(data);
    };
    RawFirestore.prototype.updateCreateCharacterConstants = function (data) {
        return this.createCharacterConstantsDoc().update(data);
    };
    RawFirestore.prototype.updateGameConstants = function (data) {
        return this.gameConstantsDoc().update(data);
    };
    RawFirestore.prototype.updateShopConstants = function (data) {
        return this.shopConstantsDoc().update(data);
    };
    RawFirestore.prototype.getVersion = function (version) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.versionDoc(version).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.updateVersion = function (version, data) {
        return this.versionDoc(version).update(data);
    };
    RawFirestore.prototype.listenVersion = function (version, onNext) {
        var off = this.versionDoc(version).onSnapshot(onNext);
        return off;
    };
    RawFirestore.prototype.updateLifeCards = function (version, data) {
        return this.lifeCardsDoc(version).update(data);
    };
    RawFirestore.prototype.getAllSkills = function (version) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.allSkillsDoc(version).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.updateAllSkills = function (version, data) {
        return this.allSkillsDoc(version).update(data);
    };
    RawFirestore.prototype.getValues = function (version) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.valuesDoc(version).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.setValues = function (version, data) {
        return this.valuesDoc(version).set(data);
    };
    RawFirestore.prototype.getCompanyValues = function (version) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.companyValuesDoc(version).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.setCompanyValues = function (version, data) {
        return this.companyValuesDoc(version).set(data);
    };
    RawFirestore.prototype.getEducationLevels = function (version) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.educationLevelsDoc(version).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.clearEducationQuestions = function (version) {
        return __awaiter(this, void 0, void 0, function () {
            var snapshot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.educationQuestionsCollection(version).get()];
                    case 1:
                        snapshot = _a.sent();
                        return [2 /*return*/, Promise.all(snapshot.docs.map(function (doc) { return doc.ref.delete(); }))];
                }
            });
        });
    };
    RawFirestore.prototype.getEducationQuestions = function (version, part) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.educationQuestionsDoc(version, part).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.setEducationQuestions = function (version, part, data) {
        return this.educationQuestionsDoc(version, part).set(data);
    };
    RawFirestore.prototype.clearQuestionsAmounts = function (version) {
        return __awaiter(this, void 0, void 0, function () {
            var snapshot;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.questionsAmountCollection(version).get()];
                    case 1:
                        snapshot = _a.sent();
                        return [2 /*return*/, Promise.all(snapshot.docs.map(function (doc) { return doc.ref.delete(); }))];
                }
            });
        });
    };
    RawFirestore.prototype.getQuestionsAmount = function (version, study) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.questionsAmountStudyDoc(version, study).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.setQuestionsAmount = function (version, study, data) {
        return this.questionsAmountStudyDoc(version, study).set(data);
    };
    RawFirestore.prototype.updateEducationLevels = function (version, data) {
        return this.educationLevelsDoc(version).update(data);
    };
    RawFirestore.prototype.getSectorSkills = function (version, sector) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sectorSkillsDoc(version, sector).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.setSectorSkills = function (version, sector, data) {
        return this.sectorSkillsDoc(version, sector).set(data);
    };
    RawFirestore.prototype.getCareerTree = function (version, sector) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.careerTreeDoc(version, sector).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.setCareerTree = function (version, sector, data) {
        return this.careerTreeDoc(version, sector).set(data);
    };
    RawFirestore.prototype.getCompanies = function (version, sector) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.companiesDoc(version, sector).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.setCompanies = function (version, sector, data) {
        return this.companiesDoc(version, sector).set(data);
    };
    RawFirestore.prototype.getHighestPositions = function (version, sector) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.highestPositionsDoc(version, sector).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.setHighestPositions = function (version, sector, data) {
        return this.highestPositionsDoc(version, sector).set(data);
    };
    RawFirestore.prototype.getJobDescription = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.jobDescriptionDoc(params).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.setJobDescription = function (params, data) {
        return this.jobDescriptionDoc(params).set(data);
    };
    RawFirestore.prototype.getJobQuestions = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.jobQuestionsDoc(params).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.setJobQuestions = function (params, data) {
        return this.jobQuestionsDoc(params).set(data);
    };
    RawFirestore.prototype.getJobSalary = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.jobSalaryDoc(params).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.setJobSalary = function (params, data) {
        return this.jobSalaryDoc(params).set(data);
    };
    RawFirestore.prototype.getJobSkills = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.jobSkillsDoc(params).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.setJobSkills = function (params, data) {
        return this.jobSkillsDoc(params).set(data);
    };
    // Data: games
    RawFirestore.prototype.getGame = function (gameCode) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.gameDoc(gameCode).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.onGame = function (gameCode, onNext) {
        var off = this.gameDoc(gameCode).onSnapshot(onNext);
        return off;
    };
    RawFirestore.prototype.getPlayer = function (gameCode, playerEmail) {
        return __awaiter(this, void 0, void 0, function () {
            var doc;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.playerDoc(gameCode, playerEmail).get()];
                    case 1:
                        doc = _a.sent();
                        return [2 /*return*/, doc.data()];
                }
            });
        });
    };
    RawFirestore.prototype.mergePlayer = function (gameCode, playerEmail, data) {
        return this.playerDoc(gameCode, playerEmail).set(data, { merge: true });
    };
    RawFirestore.prototype.onLimitedEducationGameModRound = function (params) {
        var gameCode = params.gameCode, round = params.round, onNext = params.onNext;
        var off = this.limitedEducationGameModRoundDoc(gameCode, round).onSnapshot(onNext);
        return off;
    };
    return RawFirestore;
}());
exports.RawFirestore = RawFirestore;
