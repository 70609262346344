import styled from 'styled-components';

import { Section } from 'components/section';
import { Button } from 'components/button';
import { Icon } from 'components/icons';
import { COLORS, Theme } from 'theme';

const { BorderedContainer } = Theme;

export const NewGameStyle = {
  Wrapper: styled(Section)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    height: 100%;
  `,
  MarginWrapper: styled.div`
    margin-top: 42px;
  `,
  HeadContainer: styled(BorderedContainer)`
    width: 920px;
    justify-content: center;
    margin-bottom: 32px;
    margin-top: 50px;
  `,

  Container: styled(BorderedContainer)`
    width: 920px;
    justify-content: space-between;
  `,

  TitleDescriptionContainer: styled.div`
    margin-top: 16px;
  `,

  DefaultJobLimitsContainer: styled(BorderedContainer)`
    width: 920px;
    justify-content: space-between;
    margin-top: 32px;
  `,
  ButtonContainer: styled.div`
    align-self: flex-end;
    margin: 60px 40px;
    width: 280px;
  `,
  LimitsTip: styled.p`
    color: ${COLORS.red};
  `,
  Button: styled(Button)<{ isLoading: boolean }>`
    width: 100%;
    height: 56px;

    &:disabled {
      background-color: ${COLORS.gray};
      cursor: ${({ isLoading }) => (isLoading ? 'wait' : 'not-allowed')};
    }
  `,

  LogoIcon: styled(Icon)`
    filter: grayscale(100%);
    width: 228px;
  `,
  IconEditWrapper: styled.div`
    cursor: pointer;
  `,
};
