import { ReactNode } from 'react';
import styled from 'styled-components';

import { Theme } from 'theme';

interface IProps {
  children?: ReactNode;
}

export const PlayersListStyles = {
  Block: styled.div<{ marginTop?: string }>`
    margin-top: ${(props) => props.marginTop};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  `,
  ChildrenWrapper: styled.div`
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    margin: 0 40px 20px 40px;
    align-self: center;
  `,
  Title: styled(Theme.Title)`
    margin-top: 32px;
    margin-bottom: 32px;
    flex: 1;
  `,
};

export const PlayersListLayout = (props: IProps) => (
  <Theme.SectionWrapper>
    <PlayersListStyles.ChildrenWrapper>
      {props.children}
    </PlayersListStyles.ChildrenWrapper>
  </Theme.SectionWrapper>
);
