import firebase from 'firebase/app';
import { TGameVersion } from '@avid/common';

import { firebaseApp } from './app';

interface IVersionFileParams {
  version: TGameVersion;
  path: string;
  file: File;
}

class FirebaseStorage {
  private storage: firebase.storage.Storage;

  constructor(app: firebase.app.App) {
    this.storage = app.storage();
  }

  private rootRef() {
    return this.storage.ref();
  }

  private constantsRef() {
    return this.rootRef().child('constants');
  }

  private constantsFileRef(fileName: string) {
    return this.constantsRef().child(fileName);
  }

  private constantsVersionsRef() {
    return this.constantsRef().child('versions');
  }

  private constantVersionRef(version: TGameVersion) {
    return this.constantsVersionsRef().child(version);
  }

  private constantVersionFileRef(version: TGameVersion, path: string) {
    return this.constantVersionRef(version).child(path);
  }

  public uploadConstantsFile(path: string, file: File) {
    return this.constantsFileRef(path).put(file);
  }

  public uploadConstantsVersionFile(params: IVersionFileParams) {
    const { file, path, version } = params;
    return this.constantVersionFileRef(version, path).put(file);
  }

  public getConstantsFileUrl(path: string) {
    return this.constantsFileRef(path).getDownloadURL();
  }

  public getConstantsVersionFileUrl(version: TGameVersion, path: string) {
    return this.constantVersionFileRef(version, path).getDownloadURL();
  }
}

export const firebaseStorage = new FirebaseStorage(firebaseApp);
