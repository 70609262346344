import styled, { css } from 'styled-components';

import { Section } from 'components/section';
import { Button as StyledButton } from 'components/button';
import { Icon } from 'components/icons';
import { TextArea } from 'components/text-area';
import { TextInput } from 'components/text-input';

import { DEFAULT_INPUT_WITH, DEFAULT_TEXTAREA_WIDTH } from './config';
import { FONTS } from './fonts';
import { COLORS } from './colors';
import { FONT_SIZE } from './font-size';

interface IParagraph {
  fontSize?: string;
  fontFamily?: string;
  fontColor?: string;
}

interface IParagraphProps {
  font?: keyof typeof FONTS;
  fontSize?: keyof typeof FONT_SIZE;
}

interface IButtonSmall {
  isClear?: boolean;
  darkTheme?: boolean;
}

interface IIconButton {
  justifyContent?: string;
  marginLeft?: string;
}

interface IButtonMedium {
  alignSelf?: string;
}

interface IBorderedContainer {
  flexDirection?: string;
}

export const Theme = {
  BorderedContainer: styled.div<IBorderedContainer>`
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
    background-color: ${COLORS.blue_gray};
    padding: 30px;
    height: max-content;
    border-radius: 10px;
    border: 1px solid ${COLORS.light_gray};
  `,

  ContainerColumn: styled.div`
    display: flex;
    flex-direction: column;
  `,

  ControlTitle: styled.span`
    display: block;
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 14px;
    font-family: ${FONTS.regular};
  `,

  TextInput: styled(TextInput)`
    margin-bottom: 16px;
    & input {
      width: ${DEFAULT_INPUT_WITH}px;
    }
  `,

  TextInputSmall: styled(TextInput)`
    margin-bottom: 16px;
    & input {
      width: 134px;
    }
  `,

  TextArea: styled(TextArea)<{ width?: number }>`
    width: ${({ width }) => `${width || DEFAULT_TEXTAREA_WIDTH}px`};
    margin-bottom: 16px;
  `,

  Title: styled.p`
    font-family: ${FONTS.semiBold};
    font-size: 24px;
    margin: 0;
  `,

  SubTitle: styled.p`
    font-family: ${FONTS.quicksandBold};
    font-size: 18px;
    color: ${(props) => props.color};
    margin-bottom: 0;
  `,

  Anchor: styled.a<IParagraph>`
    align-self: flex-end;
    font-family: ${FONTS.regular};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #0b1836;
    margin-bottom: 24px;
    cursor: pointer;
  `,

  Button: styled(StyledButton)`
    align-self: flex-end;
    width: 280px;
    height: 56px;
    margin-bottom: 40px;
    margin-top: 80px;
  `,
  ButtonMedium: styled(StyledButton)<IButtonMedium>`
    align-self: ${({ alignSelf }) => alignSelf || 'flex-end'};
    width: 190px;
    height: 44px;
  `,
  ButtonSmall: styled(StyledButton)<IButtonSmall>`
    align-self: flex-end;
    width: 95px;
    height: 44px;
    border: ${(props) => {
      return (!props.isClear && `1px solid ${COLORS.violet}`) || 'none';
    }};
    background: ${({ darkTheme }) =>
      (darkTheme && COLORS.violet) || COLORS.white};
    color: ${({ darkTheme }) => (darkTheme && COLORS.white) || COLORS.black};
    border-radius: 10px;
    box-shadow: ${(props) =>
      props.isClear && '2px 6px 16px rgba(45, 45, 55, 0.1)'};
  `,

  IconButton: styled.div<IIconButton>`
    display: flex;
    flex: 1;
    max-width: 70px;
    justify-content: ${({ justifyContent }) => justifyContent};
    cursor: pointer;
    margin-left: ${({ marginLeft }) => `${marginLeft}px`};
  `,

  LogoIcon: styled(Icon)`
    filter: grayscale(100%);
    width: 228px;
  `,

  InfoField: styled(TextInput)`
    margin-bottom: 16px;
    & input {
      width: 134px;
      background: #fbfbff;
      box-shadow: none;
      padding-left: 0;
    }
  `,

  Paragraph: styled.p<IParagraphProps>`
    margin: 0;
    font-family: ${({ font }) => FONTS[font ?? 'regular']};
    font-size: ${({ fontSize }) => FONT_SIZE[fontSize ?? 'label']};
  `,

  Text: styled.span<IParagraphProps>`
    font-family: ${({ font }) => FONTS[font ?? 'regular']};
    font-size: ${({ fontSize }) => FONT_SIZE[fontSize ?? 'label']};
  `,

  HighlightedParagraph: styled.p`
    font-family: ${FONTS.regular};
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 5px;
    margin-bottom: 14px;
  `,

  TextRow: styled.div`
    display: flex;
    flex-direction: row;
  `,

  ErrorMessage: styled.span`
    margin-bottom: 5px;
    margin-top: 5px;
    font-size: 14px;
    font-family: ${FONTS.regular};
    color: ${COLORS.red};
  `,
  CentredContainer: styled.div<{ direction?: 'column' | 'row' }>`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ direction }) => direction && `flex-direction: ${direction}`};
  `,
  SectionWrapper: styled(Section)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    height: 100%;
    background-color: ${COLORS.white};
  `,
};

export const Css = {
  scrollBar: css`
    &::-webkit-scrollbar {
      display: block;
      height: 6px;
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 5px;
      background-color: ${COLORS.scrollbar_track};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${COLORS.violet};
      border-radius: 10px;
      border-right: none;
      border-left: none;
    }
  `,
};
