
import styled from 'styled-components';

import { H3, Paragraph } from 'components/typography';
import { Grid } from 'components/UI';
import { Section } from 'components/section';
import { Icon } from 'components/icons';
import { COLORS, typographyCss } from 'theme';
import { useGameUpload } from './games-upload.state';
import { Button } from 'components/button';

const Styles = {
  Root: styled(Section)`
    padding-left: 40px;
    padding-right: 40px;
  `,
  MainWrapper: styled(Grid)`
    margin-top: 64px;
  `,
  Tip: styled(Paragraph)<{ color?: string }>`
    justify-self: end;
    text-decoration-line: underline;
    color: ${({ color = COLORS.blackGray }) => color};
  `,
  Picker: styled(Grid)`
    width: 415px;
    height: 145px;
    border-radius: 20px;
    justify-content: center;
    justify-items: center;
    align-content: center;
    row-gap: 12px;
    border: 2px solid #fff;
    box-shadow: 0px 20px 70px 0px rgba(86, 89, 146, 0.1);
    background: rgba(255, 255, 255, 0.3);
  `,
  TextWrapper: styled(Grid)`
    align-content: center;
  `,
  Link: styled.span`
    ${typographyCss.QuicksandMedium16}
    color: #00AEEF;
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
  `,
  Input: styled.input`
    visibility: hidden;
    height: 1px;
  `,
};

export const GamesUpload = () => {
  const {
    isError,
    onClickLink,
    onChangeFile,
    onUpload,
    isUploadDisabled,
    inputRef,
  } = useGameUpload();

  const tipColor = isError ? COLORS.red : COLORS.blackGray;
  const tipText = isError ? 'Error occurred' : 'Download sample file';

  return (
    <Styles.Root>
      <Styles.MainWrapper rowGap="80px">
        <Grid rowGap="32px">
          <H3 typography="RalewayBold24">Bulk Create Games</H3>

          <Grid>
            <Styles.Tip typography="QuicksandRegular14" color={tipColor}>
              {tipText}
            </Styles.Tip>

            <Styles.Picker>
              <Icon type="dragNDrop" />

              <Styles.TextWrapper rowGap="0">
                <Paragraph textAlign="center" typography="QuicksandMedium16">
                  Drag and drop file
                </Paragraph>
                <Paragraph textAlign="center" typography="QuicksandMedium16">
                  or
                </Paragraph>

                <Styles.Link onClick={onClickLink}>browse</Styles.Link>
                <Styles.Input
                  id="games-file-input"
                  type="file"
                  accept=".csv"
                  ref={inputRef}
                  onChange={onChangeFile}
                />
              </Styles.TextWrapper>
            </Styles.Picker>
          </Grid>
        </Grid>

        <Button isDisabled={isUploadDisabled} onClick={onUpload}>
          Upload
        </Button>
      </Styles.MainWrapper>
    </Styles.Root>
  );
};
