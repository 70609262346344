import { callableFunctions } from 'services/api';
import { fetch, update } from 'services/firebase.api';
import { getGameIdentity } from 'services/utils';

export const createGameApi = (gameCode: string) => ({
  fetchGame: () => fetch('games', gameCode),

  finishGameAsync: () => update(`games`, { status: 'finished' }, gameCode),

  makeRoundSnapshot: (round: number) => {
    try {
      return callableFunctions.roundSnapshot({
        gameIdentity: getGameIdentity(gameCode),
        round,
      });
    } catch (error) {
      console.error(error);
    }
  },
});
