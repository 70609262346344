import styled from 'styled-components';

import { Section } from 'components/section';
import { Theme } from 'theme';

import { TableButton } from './components';

export const PlayersHistoryStyles = {
  Root: styled(Section)`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 16px;
    padding: 0 32px;
  `,
  WidthContainer: styled.div`
    max-width: 2000px;
    margin: 0 auto;
  `,
  ControlsContainer: styled.div`
    display: flex;
  `,
  HeadButton: styled(TableButton)<{ isMargin: boolean }>`
    height: 56px;
    ${({ isMargin }) => isMargin && 'margin-left: 32px;'}
    align-self: center;
    width: auto;
    transform: translateY(-5px);
  `,
  NoGames: styled(Theme.Title)`
    margin-top: 128px;
    width: 100%;
    text-align: center;
  `,
  TableWrapper: styled.div`
    width: 100%;
    overflow-x: auto;
  `,
};
