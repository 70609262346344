
import styled from 'styled-components';

import { STRINGS } from 'constants/string';
import { COLORS } from 'theme/colors';

import { LimitsBlock } from '../limits-block';
import { IDS } from '../../home.constants';
import { HomeStyle } from '../../home.style';

import { useEmptyHome } from './empty-home.state';

const { EMPTY_CREATE_GAME } = IDS.BUTTONS;

interface IHomeEmpty {
  gameCreate: () => void;
  isLoading: boolean;
}

const { noCreated, pleaseClick, createGameButton } = STRINGS.HOME;

const LimitsBlockStyled = styled(LimitsBlock)`
  max-width: 795px;
`;

export const HomeEmpty = ({ gameCreate, isLoading }: IHomeEmpty) => {
  const {
    isAllowed,
    isPlatformUser,
    isLoading: isLoadingLimits,
    limits,
  } = useEmptyHome();

  const RenderLimitsBlock = isPlatformUser && (
    <LimitsBlockStyled isLoading={isLoadingLimits} limits={limits} />
  );

  return (
    <HomeStyle.Wrapper backgroundColor={COLORS.white}>
      <HomeStyle.GameCreateBlock>
        <HomeStyle.Title>{noCreated}</HomeStyle.Title>

        {RenderLimitsBlock}

        <HomeStyle.Tip>{pleaseClick}</HomeStyle.Tip>
        <HomeStyle.EmptyGameBlock>
          <HomeStyle.Button
            id={EMPTY_CREATE_GAME}
            text={createGameButton}
            onClick={gameCreate}
            isLightTheme={false}
            isDisabled={isLoading || !isAllowed}
          />
          <HomeStyle.Icon type="noGames" />
        </HomeStyle.EmptyGameBlock>
      </HomeStyle.GameCreateBlock>

      {isLoading && (
        <HomeStyle.Spinner>
          <HomeStyle.Title>LOADING...</HomeStyle.Title>
        </HomeStyle.Spinner>
      )}
    </HomeStyle.Wrapper>
  );
};
