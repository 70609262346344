import { FC } from 'react';

import { IComponent } from 'typings';

import { SectionStyles, TSectionStyles } from './section.styles';

interface ISection extends IComponent, TSectionStyles {}

export const Section: FC<ISection> = (props) => {
  const { children, className, backgroundColor, isFlexCenter } = props;
  return (
    <SectionStyles.Container
      backgroundColor={backgroundColor}
      isFlexCenter={isFlexCenter}
      className={className}
    >
      {children}
    </SectionStyles.Container>
  );
};
