"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClientFirebase = void 0;
var raw_firestore_service_1 = require("./raw-firestore.service");
var firestore_service_1 = require("./firestore.service");
var ClientFirebase = /** @class */ (function () {
    function ClientFirebase(app) {
        this.app = app;
        this.firestore = new firestore_service_1.Firestore(new raw_firestore_service_1.RawFirestore(this.app.firestore()));
    }
    return ClientFirebase;
}());
exports.ClientFirebase = ClientFirebase;
