import styled from 'styled-components';

import { Section } from 'components/section';

import { Theme } from 'theme/styles';

const { Title } = Theme;

export const StatisticStyle = {
  Wrapper: styled(Section)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    height: 100%;
    padding-bottom: 24px;
  `,

  EmptyGameTitle: styled(Title)`
    margin-top: 32px;
    margin-bottom: 32px;
    flex: 1;
    display: flex;
    align-items: center;
  `,
};
