const splitRow = (text: string) => {
  const values: string[] = [];

  let insideQuotes = false;
  let currentValue = '';

  for (let i = 0; i < text.length; i++) {
    const char = text[i];

    if (char === '"') {
      insideQuotes = !insideQuotes;
    } else if (char === ',' && !insideQuotes) {
      values.push(currentValue.trim());
      currentValue = '';
    } else {
      currentValue += char;
    }
  }

  values.push(currentValue.trim());

  return values;
};

export class CSVReaderService {
  private static getFileText(file: File) {
    const fileReader = new FileReader();

    const promise = new Promise<string | ArrayBuffer>((resolve, reject) => {
      fileReader.onload = (ev) => {
        const result = ev.target?.result;

        if (!result) {
          reject();
          return;
        }

        resolve(result);
      };
    });

    fileReader.readAsText(file);

    return promise;
  }

  // [
  //   { Header1: 'val1', Header2: 'val2' },
  //   { Header1: 'val3', Header2: 'val4' },
  // ];
  private static getArrayFromText(fileText: string) {
    const headerRow = fileText.slice(0, fileText.indexOf('\r\n'));
    const headers = splitRow(headerRow);

    const csvRows = fileText.slice(fileText.indexOf('\n') + 1).split('\r\n');
    const rows = csvRows.filter((row) => row).map((row) => splitRow(row));

    const array = rows.map((row) =>
      row.reduce(
        (acc, current, index) => {
          acc[headers[index]] = current;

          return acc;
        },
        {} as Record<string, string>
      )
    );

    return array;
  }

  public static async readAsArray(file: File) {
    const fileText = await this.getFileText(file);

    return this.getArrayFromText(fileText as string);
  }
}
