import styled from 'styled-components';

import { Theme } from 'theme/styles';
import { FONTS } from 'theme/fonts';

export const PlayersListStyles = {
  InfoTitle: styled.p`
    font-family: ${FONTS.semiBold};
    font-size: 18px;
    margin-bottom: 16px;
    margin-top: 0;
  `,
  NoGames: styled(Theme.Title)`
    margin-top: 128px;
    width: 100%;
    text-align: center;
  `,
};
