import styled from 'styled-components';

import { Icon } from 'components/icons';

import { COLORS } from 'theme/colors';
import { FONTS } from 'theme/fonts';

export const HeaderStyle = {
  Root: styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-right: 40px;
    cursor: pointer;
    margin-top: 15px;

    @media print {
      display: none;
    }
  `,

  UserName: styled.p`
    font-size: 20px;
    line-height: 23px;
    font-family: ${FONTS.semiBold};
  `,

  Triangle: styled(Icon)`
    margin-left: 5px;
  `,

  UserIcon: styled(Icon)`
    align-self: center;
    margin-right: 8px;
    width: 24px;
  `,

  MenuIcon: styled(Icon)`
    align-self: center;
    margin-right: 16px;
  `,

  Menu: styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    height: max-content;
    border-radius: 10px;
    width: 183px;
    top: 55px;
    right: 0;
    background: white;
    align-items: center;
    box-shadow: 2px 6px 16px rgba(45, 45, 55, 0.1);
    z-index: 10;
  `,

  MenuItem: styled.div`
    font-family: ${FONTS.regular};
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${COLORS.black};
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
  `,
};
