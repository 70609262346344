import styled from 'styled-components';
import { RouteChildrenProps } from 'react-router-dom';

import { StatisticMenu } from 'components/statistic-menu';
import { COLORS, Theme } from 'theme';

import { LogsTable, RealTimeTable } from './components';
import { usePLayersHistoryState } from './players-history.state';
import { PlayersHistoryStyles as Styles } from './players-history.styles';
import { STRINGS, BUTTONS } from './players-history.constants';
interface IProps extends RouteChildrenProps<null | { id: string }, string> {
  shareGameCode?: string;
}

const { EXPORT, RT } = BUTTONS;
const { TITLE } = STRINGS;

export const SectionTitle = styled(Theme.Title)`
  margin-top: 32px;
  margin-bottom: 32px;
`;

export const PlayersHistoryScreen = (props: IProps) => {
  const { shareGameCode, location } = props;

  const {
    selectedGameCode,
    isDisplayTable,
    isActiveGameSelected,
    isGamesExist,

    onSwitch,
    selectGame,
  } = usePLayersHistoryState(shareGameCode, location.state);

  const TableComponent = isActiveGameSelected
    ? isDisplayTable
      ? LogsTable
      : RealTimeTable
    : LogsTable;

  const buttonText = isDisplayTable
    ? RT
    : shareGameCode
      ? 'Freeze to view all'
      : EXPORT;

  const RenderHistory =
    shareGameCode || isGamesExist ? (
      selectedGameCode && (
        <TableComponent
          sharedGameCode={shareGameCode}
          gameCode={selectedGameCode}
        />
      )
    ) : (
      <Styles.NoGames>You have no games yet</Styles.NoGames>
    );

  const RenderMenu = !shareGameCode && (
    <StatisticMenu
      currentGameCode={selectedGameCode}
      selectGame={selectGame}
      hideRounds
      isSmall
    />
  );

  const RenderHeadBUtton = isActiveGameSelected && (
    <Styles.HeadButton
      text={buttonText}
      onClick={onSwitch}
      isMargin={!shareGameCode}
    />
  );

  return (
    <Styles.Root backgroundColor={COLORS.white}>
      <div>
        <Styles.WidthContainer>
          <div>
            <SectionTitle>{TITLE}</SectionTitle>

            <Styles.ControlsContainer>
              {RenderMenu}
              {RenderHeadBUtton}
            </Styles.ControlsContainer>
          </div>

          {RenderHistory}
        </Styles.WidthContainer>
      </div>
    </Styles.Root>
  );
};
