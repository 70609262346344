import { CSVLink } from 'react-csv';

import { COLORS } from 'theme/colors';
import { STRINGS } from 'constants/string';

import { useExportDataState } from './export-data.state';
import { HEADERS } from './export-data.constants';
import { ExportDataStyles } from './export-data.styles';

const {
  EXPORT_DATA: { buttonTitle },
} = STRINGS;

export const ExportData = () => {
  const {
    csvLinkRef,
    exportData,
    isLoading,
    progress,
    isOpenDownloadWindow,
    onExportData,
  } = useExportDataState();

  const fileName = `all-data.csv`;

  const RenderContent = isLoading ? (
    <p>{`Loading... ${progress[0]}/${progress[1]}`}</p>
  ) : (
    <>
      <CSVLink
        headers={HEADERS}
        data={exportData}
        filename={fileName}
        ref={csvLinkRef}
        className="hidden"
        onClick={isOpenDownloadWindow}
        target="_blank"
      />
      <ExportDataStyles.ExportButton
        text={buttonTitle}
        onClick={onExportData}
        isDisabled={isLoading}
      />
    </>
  );

  return (
    <ExportDataStyles.Container backgroundColor={COLORS.white}>
      <ExportDataStyles.ExportButtonWrapper>
        {RenderContent}
      </ExportDataStyles.ExportButtonWrapper>
    </ExportDataStyles.Container>
  );
};
