import styled, { css } from 'styled-components';

import { COLORS } from 'theme/colors';

interface ISectionContainerStyles {
  backgroundColor?: string;
  isFlexCenter?: boolean;
}

export type TSectionStyles = ISectionContainerStyles;

const FlexCenter = css`
  display: flex;
  background: ${COLORS.violet};
  justify-content: center;
  align-items: center;
`;

export const SectionStyles = {
  Wrapper: styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    height: 100%;
    background: ${COLORS.violet};
  `,
  Container: styled.div<ISectionContainerStyles>`
    min-height: 100vh;
    min-width: 1150px;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: ${(p) => p.backgroundColor};
    ${(p) => p.isFlexCenter && FlexCenter}
    display: flex;
    flex: 1;
  `,
};
